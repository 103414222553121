// Write your code here
import React, {Component} from 'react'
import $ from 'jquery'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import Button from 'react-bootstrap/Button'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import 'font-awesome/css/font-awesome.min.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import SuggestionItem from '../SuggestionItem'
import './index.css'
import logo from '../../images/logoFarsijoo.png'
import 'bootstrap/dist/css/bootstrap.min.css'

const TRANSLATE_API = 'https://qstl-cms.herokuapp.com/api/translate'

// const translate = require('translate-api');

class GoogleSuggestions extends Component {
  state = {
    searchInput: '',
    // searchWords:'',
    // suggsKeywords:'',
    // suggs:[],
    // showSuggs:false,
  }

  constructor(props) {
    super(props)

    // searchWords was the input from the user
    // suggsKeywords was the keywords of the suggs
    // suggs were the array from the google response
    // showSuggs means we should show the suggs if the searchWords was the same as the suggsKeywords
    this.state = {
      searchWords: '',
      suggsKeywords: '',
      suggs: [],
      showSuggs: false,
      searchtype: 0,
      realSearchWords: '',
    }
    this.inputReference = React.createRef()
  }

  onChangeInput = event => {
    this.setState({
      searchInput: event.target.value,
    })
    this.requestSuggestions(event.target.value)
  }

  onFullSuggestion = suggestion => {
    this.setState({searchInput: suggestion})
    this.inputReference.current.focus()
    this.setState({suggsKeywords: '', suggs: []})
  }

  renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Click to Search
    </Tooltip>
  )

  handleSeachTypeChange = val => {
    this.setState(prevState => ({
      searchtype: val,
    }))
  }

  handleSearchKeyDown = e => {
    if (e.key === 'Enter') {
      this.redirectSearchQuery()
    }
  }

  redirectSearchQuery = () => {
    const {searchInput, suggs, searchtype} = this.state
    if (searchInput === undefined) return
    if (searchtype === 0) {
      window.location.href = `http://www.google.com/search?q=${encodeURIComponent(
        searchInput,
      )}`
    }
    if (searchtype === 1) {
      window.location.href = `http://www.google.com/search?tbm=isch&oq=${encodeURIComponent(
        searchInput,
      )}&q=${encodeURIComponent(searchInput)}`
    }
    if (searchtype === 2) {
      window.location.href = `https://www.google.com/search?tbm=vid&oq=${encodeURIComponent(
        searchInput,
      )}&q=${encodeURIComponent(searchInput)}`
    }
    if (searchtype === 3) {
      window.location.href = `https://www.google.com/search?tbm=nws&oq=${encodeURIComponent(
        searchInput,
      )}&q=${encodeURIComponent(searchInput)}`
    }

    if (searchtype === 4) {
      window.location.href = `https://www.google.com/search?tbm=bks&oq=${encodeURIComponent(
        searchInput,
      )}&q=${encodeURIComponent(searchInput)}`
    }
  }

  fetchTranslation = async word => {
    try {
      const res = await fetch(`${TRANSLATE_API}?word=${word}`)
      const data = await res.json()
      this.setState(prevState => ({
        suggs: [data.data, ...prevState.suggs],
      }))
    } catch (error) {
      console.log(error)
    }
  }

  requestSuggestions = async keywords => {
    if (keywords.length === 0) {
      this.setState({suggsKeywords: '', suggs: []})
      return
    }
    const words = keywords.split(' ').filter(n => n !== '')
    const urlKeyword = encodeURIComponent(words[words.length - 1].toLowerCase())
    const urlKeywords = encodeURIComponent(keywords.toLowerCase())
    this.setState({suggsKeywords: urlKeywords, suggs: []})

    $.ajax({
      url: `https://translate.googleapis.com/translate_a/single?client=gtx&dt=t&sl=en&tl=fa&q=${keywords}`,
      type: 'GET',
      success: datatrans => {
        this.setState({suggsKeywords: urlKeywords, suggs: []})
        // data[0] was the keywords to search
        // data[1] was the array of the google suggestion keywords
        //  console.log(datatrans, datatrans[0][0][0]);

        /*  if (keywords[keywords.length - 1] === " ") {          
          
          const trankeywords = keywords.split(" ").filter(n => n !=="");
          const lastIndex = keywords.lastIndexOf(trankeywords[trankeywords.length-1])
          let transwords = keywords.substring(0, lastIndex);
          transwords += `${datatrans[0][0][0]} `;          
          urlKeywords = encodeURIComponent(datatrans[0][0][0]);
          this.setState({ suggsKeywords: urlKeywords, suggs: [] });
          this.setState({
            searchInput: transwords.toString(),
          });
        } */

        this.setState(prevState => ({
          suggs: [...prevState.suggs, datatrans[0][0][0]],
        }))

        const url = `https://suggestqueries.google.com/complete/search?output=chrome&&gl=ir&hl=fa&q=${urlKeywords}`
        // just for CORS trick
        $.ajax({
          url,
          dataType: 'jsonp',
          type: 'GET',
          success: (data, textStatus, jqXHR) => {
            let suggestedItems = data[1]
            const suggestedTrans = []
            suggestedItems = [keywords].concat(suggestedItems)
            suggestedItems.forEach((el, index) => {
              const urlsugg = encodeURIComponent(el)
              if (/*  index > 5 */ true) {
                $.ajax({
                  url: `https://translate.googleapis.com/translate_a/single?client=gtx&dt=t&sl=en&tl=fa&q=${urlsugg}`,
                  type: 'GET',
                  success: datatranssug => {
                    this.setState(prevState => ({
                      suggs: [...prevState.suggs, datatranssug[0][0][0]],
                    }))
                  },
                })
              }
            })
          },
        })
      },
    })

    // may call this before ajax call to googleapi and here also if needed
    this.fetchTranslation(urlKeywords)
  }

  render() {
    const {searchInput, suggs, searchtype} = this.state
    const {suggestionsList} = this.props
    const filteredList = suggs

    return (
      <div className="google-suggestions-container">
        <img className="google-image" src={logo} alt="google logo" />

        <div className="suggestion-container">
          <div
            className={`input-container ${
              filteredList.length > 0 ? '__none__bottom_border' : ''
            }`}
          >
            <input
              className="input-text"
              type="search"
              placeholder="کلمه فارسی رو با حروف لاتین بنویسید تا در اینترنت جستجو کنید"
              value={searchInput}
              onChange={this.onChangeInput}
              onKeyDown={this.handleSearchKeyDown}
              ref={this.inputReference}
              lang="fa-IR"
              autoComplete="off"
            />
            <div
              className={`search-btn-container ${
                filteredList.length > 0 ? '.__none__bottom__rigth_border' : ''
              }`}
            >
              <Button
                className="google-search-btn btn-lg"
                onClick={e => {
                  e.preventDefault()
                  this.redirectSearchQuery()
                }}
                style={{marginRight: '4px'}}
              >
                <i className="fa fa-search" style={{fontSize: '24px'}} />
              </Button>
            </div>
          </div>
          {filteredList.length === 0 ? null : (
            <ul
              className={`suggestions-list ${
                filteredList.length > 0 ? '__none__top_border' : ''
              }`}
            >
              {filteredList.map(each => (
                <SuggestionItem
                  key={each.id}
                  suggestion={each}
                  onFullSuggestion={this.onFullSuggestion}
                />
              ))}
            </ul>
          )}
        </div>

        <div className="div_search_type">
          <ToggleButtonGroup
            onChange={this.handleSeachTypeChange}
            type="radio"
            name="options"
            defaultValue={0}
            className="btn-sm tg_button"
          >
            <ToggleButton
              id="Web"
              className={` ac__btn btn-sm ${
                searchtype === 0 ? 'active__ac__btn' : ''
              }`}
              value={0}
            >
              <i className="fa fa-search">&nbsp;اینترنت</i>
            </ToggleButton>
            <ToggleButton
              className={` ac__btn btn-sm ${
                searchtype === 1 ? 'active__ac__btn' : ''
              }`}
              id="Images"
              value={1}
            >
              <i className="fa fa-image">&nbsp;تصاویر</i>
            </ToggleButton>
            <br />
            <ToggleButton
              className={` ac__btn btn-sm __hs__ ${
                searchtype === 2 ? 'active__ac__btn' : ''
              }`}
              id="Videos"
              value={2}
            >
              <i className="fa fa-film">&nbsp;فیلم های</i>
            </ToggleButton>
            <ToggleButton
              className={` ac__btn btn-sm ${
                searchtype === 3 ? 'active__ac__btn' : ''
              }`}
              id="News"
              value={3}
            >
              <i className="fa fa-newspaper-o">&nbsp;اخبار</i>
            </ToggleButton>
            <ToggleButton
              className={` ac__btn btn-sm __hs__ ${
                searchtype === 4 ? 'active__ac__btn' : ''
              }`}
              id="Books"
              value={4}
            >
              <i className="fa fa-book">&nbsp;کتاب ها</i>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="footer"><a href="mailto:mike@simpleonlineadvertising.com">Contact us</a></div>
        
      </div>
    )
  }
}

export default GoogleSuggestions
